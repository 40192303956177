body {
  margin: 0;
  font-family: 'Inter', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f9;
}

::-webkit-scrollbar {
  width: 0.5rem;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 10px;
}
::-webkit-scrollbar {
  height: 3px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

code {
  font-family: 'Inter', sans-serif !important;
}
.MuiButton-root {
  font-family: 'Inter', sans-serif !important;
}

.mySnack .MuiSnackbar-root {
  position: absolute !important;
  width: 90% !important;
  top: 4.5rem !important;
  display: flex !important;
  align-items: center !important;
  z-index: 111 !important;
}

.MuiSnackbar-root .MuiAlert-filledError {
  display: flex !important;
  align-items: center !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
@media only screen and (max-width: 600px) {
  .MuiSnackbar-root .MuiAlert-filledError {
    margin-right: -15px;
  }
}
.MuiSnackbar-root .MuiPaper-elevation6 {
  box-shadow: none !important;
}
::placeholder {
  color: black !important;
}
.react-toggle-track {
  width: 77px !important;
  height: 37px !important;
  display: flex;
  align-items: center;
  background: white !important;
}
.react-toggle-thumb {
  width: 30px !important;
  height: 30px !important;
  top: 3.5px !important;
  left: 4px !important;
  background-color: grey !important;
}
.react-toggle--checked .react-toggle-thumb {
  left: 43px !important;
  background: #0094ec !important;
}
.react-toggle--focus .react-toggle-thumb {
  box-shadow: none !important;
}
.react-toggle--checked .react-toggle-track {
  background-color: white !important;
}
.react-toggle-track-check {
  position: relative !important;
  left: 0 !important;
  height: 0px !important;
  width: 0px !important;
  margin-left: 11px !important;
}
.react-toggle-track-x {
  position: relative !important;
  height: 0px !important;
  margin-left: 37px !important;
  font-weight: 600 !important;
  margin-right: 0px;
  text-transform: uppercase !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 13px !important;
  top: 1px !important;
}
.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background: white !important;
}
.react-toggle--checked .react-toggle-track-check {
  font-weight: 600 !important;
  text-transform: uppercase !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 13px !important;
  top: 1px !important;
  margin-left: 13px !important;
}
/* Animation */
.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;

  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;

  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.overflow-test {
  overflow-x: hidden !important;
  text-overflow: ellipsis !important;
}
.MuiTypography-root {
  font-family: 'inter' !important;
}
.MuiInputLabel-root {
  font-family: 'inter' !important;
}
.MuiInput-root input {
  font-family: 'Inter' !important;
}
.menu .MuiPaper-root {
  width: 192px !important;
  height: 270px !important;
}

thead[class*='ant-table-thead'] th {
  background-color: #f0ebf8 !important;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12) !important;
}
/* .MuiPaper-root ul{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
} */
/* .menu-item{
  padding: 5px 18px !important;
  justify-content: flex-start !important; 
  width: 100% !important;

} */
.reactive-btn-wrapper{
  width: 75% !important;
  height: 63px !important;
  margin-top: 1rem !important;
}
.progress-bar-linear{
  background-color: #00c30140 !important;
}
.progress-bar-linear .MuiLinearProgress-barColorPrimary{
  background-color:#00C301 !important;
}
.addeventatc{
  padding: 12px 13px 12px 16px !important;
  box-shadow: inset !important;
  border:1.5px solid #6338bc !important;
  border-radius: 7px !important;
}
.addeventatc .addeventatc_icon{
  top:3px !important;
  left:6px !important
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-disabled:hover{
  cursor: not-allowed !important;
}